import {
  Statuses,
  UserStatuses,
  userStatusesRecord,
  Webmaster,
  WebmasterRest,
} from '@rp/shared/models';

const getStatusType = (status: UserStatuses): Statuses => {
  switch (status) {
    case UserStatuses.Approved:
      return 'done';
    case UserStatuses.Declined:
      return 'canceled';
    case UserStatuses.New:
      return 'inProgress';
  }
};

const fromRestWebmaster = (webmasterRest: WebmasterRest): Webmaster => {
  return {
    id: webmasterRest.id,
    name: webmasterRest.fullName,
    email: webmasterRest.email,
    trafficSources: webmasterRest.trafficSources.map(({ name }) => name).join('/'),
    verticals: webmasterRest.verticals.map(({ name }) => name).join(', '),
    conversions: webmasterRest.conversions,
    manager: webmasterRest.managerId,
    notes: webmasterRest.notes,
    status: webmasterRest.status,
    nickname: webmasterRest.nickname,
    messengerId: webmasterRest.messengerId,
    tableStatus: userStatusesRecord[webmasterRest.status],
    statusType: getStatusType(webmasterRest.status),
  };
};

export const WebmastersAdapter = {
  fromRestWebmaster,
};
