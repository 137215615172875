import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { PaymentMethod, PayoutPayload, PayoutResponse } from '@rp/shared/models';
import { PaymentMethodAdapter } from '@rp/shared/adapters';

import { PayoutsProvider } from './payouts.provider';

@Injectable()
export class HttpPayoutsService implements PayoutsProvider {
  private readonly _baseUrl = `${environment.apiConfig.userApiHost}/payouts/webmaster/payout-methods`;

  private readonly _httpClient = inject(HttpClient);

  getPayouts(payload?: PayoutPayload): Observable<PayoutResponse> {
    return this._httpClient.get<PayoutResponse>(`${this._baseUrl}/`, {
      params: { ...payload },
    });
  }

  deletePayout(id: number): Observable<void> {
    return this._httpClient.delete<void>(`${this._baseUrl}/${id}/`);
  }

  setActive(id: number, isActive: boolean): Observable<void> {
    return this._httpClient.post<void>(`${this._baseUrl}/set_status/`, {
      walletId: id,
      isActive,
    });
  }

  createPayout(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this._httpClient.post<PaymentMethod>(
      `${this._baseUrl}/`,
      PaymentMethodAdapter.toRest(paymentMethod),
    );
  }

  verifyPayoutMethod(walletId: number, token: string): Observable<Response> {
    return this._httpClient.post<Response>(`${this._baseUrl}/verify/`, {
      walletId,
      token,
    });
  }

  resendVerification(id: number): Observable<void> {
    return this._httpClient.post<void>(`${this._baseUrl}/${id}/resend-verification/`, {
      wallet_id: id,
    });
  }
}
