import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { PaginationAdapter, WebmastersAdapter } from '@rp/shared/adapters';
import { WebmastersResponse, WebmasterRest, WebmastersRestResponse } from '@rp/shared/models';

import { WebmastersProvider } from './webmasters.provider';

@Injectable()
export class HttpWebmastersProvider implements WebmastersProvider {
  private readonly _httpClient = inject(HttpClient);

  private _baseUrl = `${environment.apiConfig.adminApiHost}/admin/users`;

  getWebmasters(params?: string): Observable<WebmastersResponse> {
    return this._httpClient
      .get<WebmastersRestResponse>(`${this._baseUrl}/webmasters/?${params || ''}`)
      .pipe(
        map(({ webmasters, ...pagination }) => ({
          webmasters: webmasters.map((webmaster: WebmasterRest) =>
            WebmastersAdapter.fromRestWebmaster(webmaster),
          ),
          ...PaginationAdapter.fromRest(pagination),
        })),
      );
  }
}
