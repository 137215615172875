import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  FormsModule,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatError,
  MatFormField,
} from '@angular/material/form-field';
import { MatRipple } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { injectNgControl } from '@rp/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { HttpImageUploaderProvider, IMAGEUPLOADER_PROVIDER_TOKEN } from '@rp/shared/providers';
import { finalize } from 'rxjs';

import { IconComponent, IconName } from '../icon';
import { InputComponent } from '../input';
import { ToastService } from '../toaster';
import { SpinnerDirective } from '../spinner';

export type UploadedImageSize = 's' | 'm' | 'l';

@Component({
  selector: 'rp-image-uploader',
  standalone: true,
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
  imports: [
    IconComponent,
    NgClass,
    MatFormField,
    MatRipple,
    FormsModule,
    MatInput,
    ReactiveFormsModule,
    InputComponent,
    MatError,
    TranslateModule,
    SpinnerDirective,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: IMAGEUPLOADER_PROVIDER_TOKEN, useClass: HttpImageUploaderProvider },
  ],
})
export class ImageUploaderComponent implements OnInit {
  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();

  size = input<UploadedImageSize>('m');
  title = input<string>('general.imageUpload');
  description = input<string>('general.dragAndDrop');
  fileParamsText = input<string>('general.fileParams');
  fileParams = input<Record<string, string>>({ height: '500', width: '500', size: '2' });
  fullWidth = input(false, {
    transform: booleanAttribute,
  });
  lazyLoad = input(false, {
    transform: booleanAttribute,
  });
  errors = signal<string | null>(null);
  imageSrc = signal<string | ArrayBuffer | null>(null);
  isLoading = signal<boolean>(false);

  imageLoad = output<string>();

  isDragging = false;
  isDisabled = false;

  protected readonly icons = IconName;
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _toast = inject(ToastService);
  private readonly _translateService = inject(TranslateService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _imageUploaderProvider = inject(IMAGEUPLOADER_PROVIDER_TOKEN);

  constructor() {
    effect(() => {
      if (this.imageSrc()) {
        this.ngControl.control.setValue(this.imageSrc());
        this._cdRef.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    if (this.ngControl.value) {
      if (this.lazyLoad()) {
        this._lazyLoadImage();
      } else {
        this.imageSrc.set(this.ngControl.value);
      }
    }

    this.ngControl.control.statusChanges.subscribe(() => {
      this._cdRef.detectChanges();
    });
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    const file = event.dataTransfer?.files[0];
    if (file) {
      this.handleFile(file);
    }
  }

  handleFile(file: File): void {
    if (file?.size > 2 * 1024 * 1024 || !file.type.startsWith('image/')) {
      this._uploadErrorHandler();

      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;

      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width >= 500 && height >= 500) {
          this.imageSrc.set(reader.result);
          this.ngControl.control.markAsTouched();
          this.ngControl.control.markAsDirty();
        } else {
          this._uploadErrorHandler();
        }
      };
    };

    reader.readAsDataURL(file);
  }

  remove(): void {
    this.ngControl.control.reset();
    this.imageSrc.set(null);
    this.ngControl.control.markAsTouched();
  }

  private _uploadErrorHandler(): void {
    this.ngControl.control.markAsDirty();
    this._toast.showToast({
      title: this._translateService.instant('general.uploadFailed'),
      type: 'error',
      text: this._translateService.instant('general.uploadFailedMessage'),
    });
  }

  private _lazyLoadImage(): void {
    this.isLoading.set(true);

    this._imageUploaderProvider
      .getImageFromS3(this.ngControl.value)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe({
        next: base64 => {
          this.imageSrc.set(base64);

          this.imageLoad.emit(base64);
        },
        error: () => this.imageSrc.set(null),
      });
  }
}
