import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgClass } from '@angular/common';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';

type Appearance = 'default' | 'success';
type Size = 'm' | 'l';

@Component({
  selector: 'rp-toggle',
  standalone: true,
  templateUrl: './toggle.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  host: {
    '[class]': '"rp-toggle__" + appearance()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, ReactiveFormsModule, NgClass],
})
export class ToggleComponent {
  readonly label = input<string>('');
  readonly appearance = input<Appearance>('default');
  readonly size = input<Size>('m');

  ngControl = injectNgControl();
}
