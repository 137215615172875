import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  Signal,
  signal,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { DateFormat } from '@rp/shared/models';

import { InputComponent } from '../input';
import { ButtonComponent } from '../button';
import { Note } from './models/note.interface';
import { AddNoteParams, GetNotesRequest, NoteEntityType } from './models';
import { CustomMatPaginatorIntl } from '../table/intl/custom-paginator.intl';
import { HttpNotesProvider, NOTES_PROVIDER_TOKEN } from './providers';
import { NotesService } from './services/notes.service';
import { SpinnerDirective } from '../spinner';

@Component({
  selector: 'rp-notes',
  standalone: true,
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    TranslateModule,
    DatePipe,
    MatPaginator,
    SpinnerDirective,
  ],
  providers: [
    NotesService,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'rp-paginator-select' },
    },
    { provide: NOTES_PROVIDER_TOKEN, useClass: HttpNotesProvider },
  ],
})
export class NotesComponent implements OnInit {
  /**
   * If pagination size is hidden
   */
  readonly hidePageSize = input<boolean, string>(false, {
    transform: booleanAttribute,
  });

  /**
   * Account type
   */
  readonly objectEntityName = input.required<NoteEntityType>();

  /**
   * Object id
   */
  readonly objectId = input.required<number>();

  /**
   * If Note has disabled state
   */
  readonly disabled = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If Note is required
   */
  readonly required = input(false, {
    transform: booleanAttribute,
  });

  readonly notes = signal<Note[]>([]);

  readonly itemsLength = signal<number>(0);
  readonly pageIndex = signal<number>(0);
  readonly page = signal<number>(1);
  readonly pageSize = signal<number>(10);
  readonly pageSizeOptions = signal<number[]>([10, 20, 50]);

  readonly isLoading = signal<boolean>(true);

  readonly control = new FormControl<string>('', Validators.maxLength(1024));
  readonly DateFormat = DateFormat;

  private readonly params: Signal<GetNotesRequest> = computed(() => ({
    page: this.page(),
    limit: this.pageSize(),
    objectEntityName: this.objectEntityName(),
    objectId: this.objectId(),
    sorting: '-id',
  }));

  private readonly _notesService = inject(NotesService);
  private readonly _destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      if (this.required()) {
        this.control.setValidators(Validators.requiredTrue);
      } else {
        this.control.removeValidators(Validators.requiredTrue);
      }

      if (this.disabled()) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    });
  }

  ngOnInit(): void {
    this._getNotes();
  }

  onAddComment(): void {
    const params: AddNoteParams = {
      text: this.control.value,
      objectEntityName: this.objectEntityName(),
      objectId: this.objectId(),
    };

    this._notesService.addNote(params).subscribe({
      next: () => {
        this._getNotes();
        this.control.reset();
      },
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex.set(event.pageIndex);
    this.pageSize.set(event.pageSize);
    this.page.set(event.pageIndex + 1);

    this._getNotes();
  }

  private _getNotes(): void {
    this.isLoading.set(true);

    this._notesService
      .getNotes(this.params())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(({ notes, itemsCount }) => {
        this.notes.set(notes);
        this.itemsLength.set(itemsCount);
        this.isLoading.set(false);
      });
  }
}
