import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe, DatePipe } from '@angular/common';

import {
  ButtonComponent,
  DIALOG_DATA,
  DialogComponent,
  DialogRef,
  DialogService,
  SpinnerDirective,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, Observable, Subject } from 'rxjs';
import { DateFormat, StructureResponse, TargetApp } from '@rp/shared/models';
import { CentsToDollarsPipe } from '@rp/shared/pipes';
import { CommonMediaQueries, MediaScreenService } from '@rp/shared/services';

import { FinanceStatusComponent } from '../finance-status/finance-status.component';
import { Payout } from '../../models/payout.interface';
import { PayoutHistory } from '../../models/payout-history.interface';
import { EditStatusDialogComponent } from '../edit-status-dialog/edit-status-dialog.component';
import { DETAILS_DIALOG_COLUMNS } from '../../consts/details-dialog-columns';
import { FinancialStatus } from '../../models/financial-status.enum';
import { FinanceService } from '../../services/finance.service';

@Component({
  selector: 'rp-details-dialog',
  standalone: true,
  templateUrl: './details-dialog.component.html',
  styleUrl: './details-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogComponent,
    TranslateModule,
    FinanceStatusComponent,
    ButtonComponent,
    SpinnerDirective,
    DatePipe,
    CentsToDollarsPipe,
    AsyncPipe,
  ],
})
export class DetailsDialogComponent implements OnInit {
  targetApp = TargetApp;
  parent = parent;
  DateFormat = DateFormat;

  isMobile$: Observable<boolean>;

  columns = signal(DETAILS_DIALOG_COLUMNS);
  items = signal<PayoutHistory[]>([]);
  data = input<Payout>({} as Payout);
  isLoading = signal<boolean>(true);
  isStatusChangeless = computed(
    () =>
      this.dialogData.payout.status === FinancialStatus.PAYOUT_REQUEST_STATUS_PAID ||
      this.dialogData.payout.status === FinancialStatus.PAYOUT_REQUEST_STATUS_CANCELED,
  );

  dialogRef = inject(DialogRef);
  dialogData: {
    target: TargetApp;
    financeService: FinanceService;
    payout: Payout;
    statuses: WritableSignal<Map<number, string>>;
    structures: WritableSignal<StructureResponse>;
    shouldUpdatePayoutsSubject: Subject<boolean>;
  } = inject(DIALOG_DATA);
  dialogService = inject(DialogService);

  private readonly destroyRef = inject(DestroyRef);
  private readonly _mediaScreenService = inject(MediaScreenService);

  ngOnInit(): void {
    this.isMobile$ = this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD, true);
    this.isLoading.set(true);

    this.dialogData.financeService
      .getInstance(this.dialogData.target)
      .getPayoutsHistory(this.dialogData.payout.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.isLoading.set(false)),
      )
      .subscribe(payouts => (payouts ? this.items.set(payouts) : []));
  }

  changeStatus(): void {
    this.dialogRef.close();

    const dialogData = { ...this.dialogData };
    dialogData.payout = { ...dialogData.payout, ...this.data() };

    this.dialogService.open(EditStatusDialogComponent, dialogData);
  }
}
