<rp-input
  [formControl]="control"
  type="textarea"
  [label]="'pages.partners.labels.notes' | translate"
  [placeholder]="'pages.partners.placeholders.notes' | translate"
/>

<rp-button type="primary" size="s" fullWidth class="action" [disabled]="!control.value" (click)="onAddComment()">
  {{ 'general.buttons.add' | translate }}
</rp-button>

<div class="history" *rpSpinner="isLoading()" [class.history__space-bottom]="itemsLength() <= 10">
  @for (note of notes(); track $index) {
    <div class="history__container">
      <div class="history__header">
        <span class="history__creator">{{ note.author.fullName }}</span>
        <span class="history__action"> {{ 'general.addedAComment' | translate }} - {{ note.createdAt | date: DateFormat.Full }} </span>
      </div>

      <div class="history__comment">{{ note.text }}</div>
    </div>
  }
</div>

@if (itemsLength() > 10) {
  <mat-paginator
    [pageSize]="pageSize()"
    [pageIndex]="pageIndex()"
    [length]="itemsLength()"
    [pageSizeOptions]="pageSizeOptions()"
    [hidePageSize]="hidePageSize()"
    (page)="onPageChange($event)"
  />
}
