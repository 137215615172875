import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordMatchValidator = (
  oldPasswordName: string,
  newPasswordName: string,
): ValidatorFn | null => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const oldPasswordControl = formGroup.get(oldPasswordName);
    const newPasswordControl = formGroup.get(newPasswordName);

    if (!oldPasswordControl || !newPasswordControl) {
      return null;
    }

    const oldPassword = oldPasswordControl.value;
    const newPassword = newPasswordControl.value;

    if (newPassword && oldPassword === newPassword) {
      newPasswordControl.setErrors({ passwordsMatch: true });
    } else {
      const currentErrors = newPasswordControl.errors;
      if (currentErrors) {
        delete currentErrors['passwordsMatch'];
        if (Object.keys(currentErrors).length === 0) {
          newPasswordControl.setErrors(null);
        } else {
          newPasswordControl.setErrors(currentErrors);
        }
      }
    }

    return null;
  };
};
