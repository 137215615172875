import { InjectionToken } from '@angular/core';

import { MessageSuccessRes, PresignedUrlReq, PresignedUrlRes } from '@rp/shared/models';
import { Observable } from 'rxjs';

export interface ImageUploaderProvider {
  createPresignedUrl(payload: PresignedUrlReq): Observable<PresignedUrlRes>;
  saveImageToS3(presignedUrl: string, photoUri: string): Observable<MessageSuccessRes>;
  getImageFromS3(url: string): Observable<string>;
}

export const IMAGEUPLOADER_PROVIDER_TOKEN = new InjectionToken<ImageUploaderProvider>(
  'ImageUploaderProvider',
);
