import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CardComponent } from '../card';
import { ButtonComponent } from '../button';
import { LazyLoadImageDirective } from '../image';

@Component({
  selector: 'rp-tile',
  standalone: true,
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardComponent, ButtonComponent, TranslateModule, LazyLoadImageDirective],
})
export class TileComponent {
  readonly image = input<string>();
  readonly languages = input<string[]>();
  readonly name = input<string>();
  readonly id = input<number>();
  readonly buttonName = input<string>();
  readonly tileAction = output<void>();
}
