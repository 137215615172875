export enum DateFormat {
  DayMonth = 'dd.MM',
  Day = 'dd.MM.YYYY',
  ShortDate = 'dd.MM.yy',
  ISO = 'yyyy-MM-dd',
  Hour = 'dd.MM.YYYY HH:mm',
  HourWithSeconds = 'dd.MM.YYYY HH:mm:ss',
  Month = 'MM.YYYY',
  Year = 'YYYY',
  DayWeek = 'dd.MM.YYYY, EEE',
  TimeWithSeconds = 'HH:mm:ss',
  Full = 'dd/MMM/yy h:mm a',
}
