import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  inject,
  input,
  output,
  QueryList,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { TabComponent } from './tab.component';

@Component({
  selector: 'rp-tabs',
  standalone: true,
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TabComponent, NgClass, NgTemplateOutlet],
})
export class TabsComponent<T> implements AfterContentInit {
  @ContentChildren(TabComponent) readonly tabs: QueryList<TabComponent<T>>;

  activeTabIndex = input<number>(0);

  readonly tabChange = output<T>();
  readonly triggerDisabledTab = output<void>();

  activeComponent: TabComponent<T>;

  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  ngAfterContentInit(): void {
    const activeTabs = this.tabs.filter(tab => tab.isActive());

    if (!activeTabs.length) {
      return;
    }

    this.activateTab(this.tabs.get(this.activeTabIndex()));
  }

  activateTab(tab: TabComponent<T>, tabElement?: HTMLLIElement): void {
    if (tab.isDisabled()) {
      this.triggerDisabledTab.emit();
    } else {
      this.tabChange.emit(tab.value());
      this.activeComponent = tab;

      const queryParams = this.activeComponent.queryParams();
      if (queryParams) {
        this._router.navigate([], {
          relativeTo: this._route,
          queryParams,
        });
      }

      if (tabElement) {
        this._scrollToTab(tabElement);
      }
    }
  }

  private _scrollToTab(tabElement: HTMLLIElement): void {
    tabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
