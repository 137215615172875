import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  ButtonComponent,
  CellComponent,
  DialogService,
  IconComponent,
  IconName,
  RowDirective,
  TableColumn,
  TableComponent,
  TableMobileComponent,
  TableWrapperComponent,
  ToastService,
} from '@rp/shared/components';
import { isFormEmpty, ObjectUtils } from '@rp/utils';
import {
  ClipboardService,
  CommonMediaQueries,
  DownloadService,
  HttpErrorHandlerService,
  MediaScreenService,
  StructuresService,
} from '@rp/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { filter, finalize, Observable, Subject } from 'rxjs';
import {
  DateFormat,
  DEFAULT_PAGINATION,
  Entity,
  PaginationRequest,
  SortingRequest,
  StructureResponse,
  TargetApp,
} from '@rp/shared/models';
import { CentsToDollarsPipe } from '@rp/shared/pipes';

import { BalanceCardsComponent } from './components/balance-cards/balance-cards.component';
import { FinanceStatusComponent } from './components/finance-status/finance-status.component';
import { FINANCE_WEBMASTER_PROVIDER_TOKEN } from './providers/finance-webmaster.provider';
import { FINANCE_ADMIN_PROVIDER_TOKEN } from './providers/finance-admin.provider';
import { HttpFinanceAdminProvider } from './providers/http-finance-admin.provider';
import { HttpFinanceWebmasterProvider } from './providers/http-finance-webmaster.provider';
import { Payout, PayoutResponse } from './models/payout.interface';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { FiltersAdminComponent } from './components/filters-admin/filters-admin.component';
import { FiltersWebmasterComponent } from './components/filters-webmaster/filters-webmaster.component';
import { AdminFiltersAdapter } from './adapters/admin-filters.adapter';
import { ADMIN_FINANCE_TABLE_COLUMN } from './consts/admin-finance-table-columns';
import { WEBMASTER_FINANCE_TABLE_COLUMN } from './consts/webmaster-finance-table-columns';
import { FinanceMobileViewComponent } from './components/finance-mobile-view/finance-mobile-view.component';
import { FinanceService } from './services/finance.service';

@Component({
  selector: 'rp-finance',
  standalone: true,
  templateUrl: './finance.component.html',
  styleUrl: './finance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CellComponent,
    RowDirective,
    TableComponent,
    ButtonComponent,
    ReactiveFormsModule,
    BalanceCardsComponent,
    TranslateModule,
    FinanceStatusComponent,
    IconComponent,
    TableMobileComponent,
    FiltersAdminComponent,
    FiltersWebmasterComponent,
    TableWrapperComponent,
    DatePipe,
    AsyncPipe,
    CentsToDollarsPipe,
    FinanceMobileViewComponent,
    RouterModule,
  ],
  providers: [
    { provide: FINANCE_WEBMASTER_PROVIDER_TOKEN, useClass: HttpFinanceWebmasterProvider },
    { provide: FINANCE_ADMIN_PROVIDER_TOKEN, useClass: HttpFinanceAdminProvider },
    FinanceService,
    DatePipe,
  ],
})
export class FinanceComponent implements OnInit {
  tableRef = viewChild(TableComponent);
  isDesktop$: Observable<boolean>;
  columns: TableColumn[];
  targetApp = TargetApp;
  icons = IconName;
  isFormEmpty = isFormEmpty;
  target: TargetApp;
  DateFormat = DateFormat;

  payouts = signal<PayoutResponse>({
    itemsCount: 0,
    payouts: [],
  });
  structures = signal<StructureResponse>(null);
  paymentMethods = signal<Map<number, string>>(null);
  paymentNetworks = signal<Map<number, string>>(null);
  payoutStatuses = signal<Map<number, string>>(null);
  isLoading = signal<boolean>(true);
  form = signal<FormGroup>(null);

  dialogService = inject(DialogService);
  mediaScreenService = inject(MediaScreenService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  financeService = inject(FinanceService);
  clipboardService = inject(ClipboardService);
  structuresService = inject(StructuresService);
  datePipe = inject(DatePipe);
  httpErrorHandlerService = inject(HttpErrorHandlerService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _toast = inject(ToastService);
  private readonly _downloadService = inject(DownloadService);

  private readonly filter = signal(null);
  private readonly pagination = signal<PaginationRequest>(DEFAULT_PAGINATION);
  private readonly sorting = signal<SortingRequest>({});

  private readonly params = computed(() =>
    ObjectUtils.objectToQueryString({
      ...ObjectUtils.stripEmptyFields(
        { ...this.filter(), ...this.pagination(), ...this.sorting() },
        true,
      ),
    }),
  );

  constructor() {
    this.isDesktop$ = this.mediaScreenService.mediaMatcher(CommonMediaQueries.MD);
    this.target = this.activatedRoute.snapshot.data['target'];

    switch (this.target) {
      case TargetApp.Admin:
        this.columns = ADMIN_FINANCE_TABLE_COLUMN;
        break;

      case TargetApp.Webmaster:
        this.columns = WEBMASTER_FINANCE_TABLE_COLUMN;
        break;

      case TargetApp.Advertiser:
        // Coming soon
        break;

      default:
        this.columns = [];
    }
  }

  ngOnInit(): void {
    this.structuresService
      .getStructures()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((structures: StructureResponse) => {
        const { paymentMethods, paymentNetworks, payoutStatuses } = structures;
        this.structures.set(structures);
        this.paymentMethods.set(
          new Map(
            paymentMethods.map((paymentMethod: Entity) => [paymentMethod.id, paymentMethod.name]),
          ),
        );
        this.paymentNetworks.set(
          new Map(
            paymentNetworks.map((paymentNetwork: Entity) => [
              paymentNetwork.id,
              paymentNetwork.name,
            ]),
          ),
        );
        this.payoutStatuses.set(
          new Map(
            payoutStatuses.map((payoutStatus: Entity) => [payoutStatus.id, payoutStatus.name]),
          ),
        );
      });

    this.getPayouts();
  }

  getPayouts(): void {
    this.isLoading.set(true);

    this.financeService
      .getInstance(this.activatedRoute.snapshot.data['target'])
      .getPayouts(this.params())
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        finalize(() => this.isLoading.set(false)),
      )
      .subscribe({
        next: payouts => this.payouts.set(payouts),
        error: err => this.httpErrorHandlerService.showErrorMessage(err),
      });
  }

  handleFilterChanged(filterForm: FormGroup): void {
    this.form.set(filterForm);
    this.filter.set(AdminFiltersAdapter.toRest(filterForm.value, this.datePipe));
  }

  openDetails(payout: Payout): void {
    if (!this.mediaScreenService.isMatched(CommonMediaQueries.MD)) {
      this.router.navigate(['finance', 'view'], {
        queryParams: {
          ...payout,
          paymentMethodId: this.paymentMethods().get(payout.paymentMethodId),
          networkId: this.paymentNetworks().get(payout.networkId),
        },
      });
    } else {
      const shouldUpdatePayoutsSubject = new Subject();
      this.dialogService.open(DetailsDialogComponent, {
        target: this.target,
        financeService: this.financeService,
        statuses: this.payoutStatuses,
        structures: this.structures,
        payout,
        shouldUpdatePayoutsSubject,
      });

      shouldUpdatePayoutsSubject
        .pipe(filter(Boolean), takeUntilDestroyed(this._destroyRef))
        .subscribe(() => this.getPayouts());
    }
  }

  onSort(sort: Sort): void {
    const { direction, active } = sort;
    const sortMap: Record<string, string> = {
      asc: active,
      desc: `-${active}`,
    };
    const sorting = sortMap[direction] ? { sorting: sortMap[direction] } : {};
    this.sorting.set(sorting);

    this.getPayouts();
  }

  onPagination(pagination: PaginationRequest): void {
    this.pagination.set(pagination);

    this.getPayouts();
  }

  onResetFilters(): void {
    this.form().reset();
    this.form().markAsPristine();
    this.getPayouts();
  }

  onSaveReport(): void {
    this.financeService
      .getInstance(this.activatedRoute.snapshot.data['target'])
      .getPayoutsCSV(this.params())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: resp => {
          this._downloadService.downloadFile(resp, 'text/csv', 'report.csv');
          this._toast.showToast({
            text: 'actions.toast.successfully',
            type: 'success',
          });
        },
        error: err => this.httpErrorHandlerService.showErrorMessage(err),
      });
  }

  apply(): void {
    this.tableRef().resetPaginator();
    this.pagination.set(DEFAULT_PAGINATION);
    this.getPayouts();
  }
}
