import { Directive, ElementRef, inject, input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[rpTooltipEllipsis]',
  standalone: true,
  hostDirectives: [
    {
      directive: MatTooltip,
      inputs: ['matTooltip: message', 'matTooltipPosition: position'],
    },
  ],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { position: 'above' },
    },
  ],
})
export class TooltipEllipsisDirective implements OnInit, OnDestroy {
  rpTooltipEllipsis = input<string | null>();

  private _resizeObserver: ResizeObserver | null = null;

  private readonly _tooltip = inject(MatTooltip);
  private readonly _elRef = inject(ElementRef);
  private readonly _renderer = inject(Renderer2);

  ngOnInit(): void {
    this._tooltip.tooltipClass = 'rp-tooltip';
    this._initResizeObserver();
  }

  ngOnDestroy(): void {
    this._resizeObserver.disconnect();
  }

  private _initResizeObserver(): void {
    this._resizeObserver = new ResizeObserver(() => this._updateTooltip());
    this._resizeObserver.observe(this._elRef.nativeElement);
  }

  private _updateTooltip(): void {
    const element = this._elRef.nativeElement;

    const isTextTruncated = element.scrollWidth > element.offsetWidth;

    if (isTextTruncated) {
      const tooltipText = this.rpTooltipEllipsis() || element.textContent.trim();

      this._tooltip.message = tooltipText;
      this._tooltip.disabled = false;
      this._renderer.addClass(this._elRef.nativeElement, 'ellipsis');
    } else {
      this._tooltip.disabled = true;
      this._renderer.removeClass(this._elRef.nativeElement, 'ellipsis');
    }
  }
}
