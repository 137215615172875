import { Injectable, inject } from '@angular/core';

import { filter, Observable } from 'rxjs';
import { MessageSuccessRes, PresignedUrlReq, PresignedUrlRes } from '@rp/shared/models';
import { IMAGEUPLOADER_PROVIDER_TOKEN, ImageUploaderProvider } from '@rp/shared/providers';

@Injectable()
export class ImageUploaderService implements ImageUploaderProvider {
  private readonly _imageUploaderProvider = inject(IMAGEUPLOADER_PROVIDER_TOKEN);

  createPresignedUrl(payload: PresignedUrlReq): Observable<PresignedUrlRes> {
    return this._imageUploaderProvider.createPresignedUrl(payload).pipe(filter(Boolean));
  }

  saveImageToS3(presignedUrl: string, photoUri: string): Observable<MessageSuccessRes> {
    return this._imageUploaderProvider.saveImageToS3(presignedUrl, photoUri);
  }

  getImageFromS3(url: string): Observable<string> {
    return this._imageUploaderProvider.getImageFromS3(url).pipe(filter(Boolean));
  }
}
