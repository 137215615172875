import { InjectionToken } from '@angular/core';

import { WebmastersResponse } from '@rp/shared/models';
import { Observable } from 'rxjs';

export interface WebmastersProvider {
  getWebmasters(params?: string): Observable<WebmastersResponse>;
}

export const WEBMASTERS_PROVIDER_TOKEN = new InjectionToken<WebmastersProvider>(
  'WebmastersProvider',
);
