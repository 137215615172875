@let isFormValueEmpty = isFormEmpty(form.value);
@let isFormInvalid = form.touched && form.invalid;

<rp-dialog type="filter" [caption]="'filters.title' | translate" (closeDialog)="onClose()">
  <ng-container dialogBody>
    <rp-button fullWidth type="secondary" [iconName]="icons.Reset" [disabled]="isFormValueEmpty" (click)="onResetFilters()">
      {{ 'general.buttons.resetFilters' | translate }}
    </rp-button>

    <form class="form" [formGroup]="form">
      <rp-input
        formControlName="name"
        subscriptSizing="dynamic"
        [iconName]="icons.Search"
        [label]="'general.name' | translate"
        [placeholder]="'general.search' | translate"
      />
      <rp-select
        formControlName="trafficSources"
        [items]="trafficSources()"
        subscriptSizing="dynamic"
        selectedField="id"
        multiple
        [label]="'general.sources' | translate"
        [placeholder]="'general.select' | translate"
        displayField="name"
      />
      <rp-select
        multiple
        formControlName="verticals"
        selectedField="id"
        displayField="name"
        subscriptSizing="dynamic"
        [items]="verticals()"
        [label]="'general.verticals' | translate"
        [placeholder]="'general.select' | translate"
      />
      <rp-geo-select
        formControlName="geos"
        [items]="geos()"
        [label]="'general.geo' | translate"
        [placeholder]="'general.placeholders.select' | translate"
      />
      <rp-select
        formControlName="paymentModel"
        [items]="paymentModels()"
        subscriptSizing="dynamic"
        [placeholder]="'general.model' | translate"
        selectedField="id"
        [label]="'general.model' | translate"
        multiple
        displayField="name"
      />

      @if (dialogData.shouldShowRates) {
        <div class="form__group">
          <rp-input
            formControlName="cpaRateFrom"
            class="form__group-control"
            rpIntegerOnly
            subscriptSizing="dynamic"
            type="number"
            [label]="'offers.table.rateCurrencyFrom' | translate"
            [placeholder]="'general.from' | translate"
          />
          <span>-</span>
          <rp-input
            formControlName="cpaRateTo"
            subscriptSizing="dynamic"
            class="form__group-control"
            rpIntegerOnly
            type="number"
            [label]="'offers.table.rateCurrencyTo' | translate"
            [placeholder]="'general.to' | translate"
          />
        </div>
        <div class="form__group">
          <rp-input
            formControlName="revsharePercentFrom"
            subscriptSizing="dynamic"
            class="form__group-control"
            rpIntegerOnly
            type="number"
            [label]="'offers.table.revsharePercentFrom' | translate"
            [placeholder]="'general.from' | translate"
          />
          <span>-</span>
          <rp-input
            formControlName="revsharePercentTo"
            subscriptSizing="dynamic"
            class="form__group-control"
            rpIntegerOnly
            type="number"
            [label]="'offers.table.revsharePercentTo' | translate"
            [placeholder]="'general.to' | translate"
          />
        </div>
      }
    </form>
  </ng-container>
  <ng-container dialogFooter>
    <rp-button fullWidth [disabled]="isFormValueEmpty || isFormInvalid" (click)="onApplyFilters()">
      {{ 'general.buttons.applyFilters' | translate }}
    </rp-button>

    <rp-button fullWidth type="secondary" (click)="onClose()">
      {{ 'general.buttons.cancel' | translate }}
    </rp-button>
  </ng-container>
</rp-dialog>
