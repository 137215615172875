import { Injectable, inject } from '@angular/core';

import { WebmastersResponse } from '@rp/shared/models';
import { WEBMASTERS_PROVIDER_TOKEN, WebmastersProvider } from '@rp/shared/providers';
import { Observable } from 'rxjs';

@Injectable()
export class WebmastersService implements WebmastersProvider {
  private _partnersProvider = inject(WEBMASTERS_PROVIDER_TOKEN);

  getWebmasters(params?: string): Observable<WebmastersResponse> {
    return this._partnersProvider.getWebmasters(params);
  }
}
