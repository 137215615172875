import {
  AfterViewInit,
  ComponentRef,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { HttpImageUploaderProvider, IMAGEUPLOADER_PROVIDER_TOKEN } from '@rp/shared/providers';

import { SpinnerComponent } from '../../spinner';

@Directive({
  selector: '[rpLazyLoadImage]',
  standalone: true,
  providers: [{ provide: IMAGEUPLOADER_PROVIDER_TOKEN, useClass: HttpImageUploaderProvider }],
})
export class LazyLoadImageDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() rpLazyLoadImage = '';

  private _imageUploaderProvider = inject(IMAGEUPLOADER_PROVIDER_TOKEN);
  private _spinnerRef: ComponentRef<SpinnerComponent> | null = null;
  private _el = inject(ElementRef);
  private _renderer = inject(Renderer2);
  private _viewContainerRef = inject(ViewContainerRef);
  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
  }

  ngAfterViewInit(): void {
    this._spinnerRef = this._viewContainerRef.createComponent(SpinnerComponent);
    this._spinnerRef.setInput('size', 'xs');

    this._imageUploaderProvider
      .getImageFromS3(this.rpLazyLoadImage)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: base64 => this._setImage(base64),
        error: () => this._setImage(''),
      });
  }

  ngOnDestroy(): void {
    this._spinnerRef?.destroy();
  }

  private _setImage(base64: string): void {
    const imageElement = this._el.nativeElement;
    this._renderer.setAttribute(imageElement, 'src', base64);

    if (!base64) {
      return;
    }

    this._renderer.setStyle(imageElement, 'display', 'block');

    imageElement.onload = () => {
      this._spinnerRef?.destroy();
    };
  }
}
