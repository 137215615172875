/**
 * Add days to given date
 * @param date
 * @param num
 */
const addDays = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() + num);

  return result;
};

/**
 * Resolves start of the day for given Date
 * @param date
 */
const startOfTheDay = (date: Date): Date => {
  const startOfTheDay = new Date(date.getTime());
  startOfTheDay.setHours(0, 0, 0, 0);

  return startOfTheDay;
};

/**
 * Resolves end of the day for given Date
 * @param date
 */
const endOfTheDay = (date: Date): Date => {
  const endOfTheDay = new Date(date.getTime());
  endOfTheDay.setHours(23, 59, 59, 999);

  return endOfTheDay;
};

/**
 * Subtracts days from given date
 * @param date
 * @param num
 */
const subtractDays = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());

  result.setDate(result.getDate() - num);

  return result;
};

/**
 * Converts a date to an ISO string without the offset.
 * @param date - The date to convert.
 * @returns The ISO string representation of the date without the offset.
 */
const toISOStringWithoutOffset = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  // If date is a string, convert it to a Date object
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Validate that the date is a valid Date object
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }

  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();
};

/**
 * Converts a date to an ISO string without the time and withot offset.
 * @param date - The date to convert.
 * @returns The ISO string representation of the date without the time.
 */
const toISOStringWithoutTime = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  return toISOStringWithoutOffset(date).slice(0, 10);
};

/**
 * Creates Date in UTC Zone.
 * @param date - Can be String or Date.
 * @returns UTC Date.
 */
const UTCDate = (date: Date | string): string => {
  if (!date) {
    return null;
  }

  return typeof date === 'string' ? date.substring(0, 19) : date.toISOString().replace('Z', '');
};

export const DateUtil = {
  startOfTheDay,
  endOfTheDay,
  subtractDays,
  addDays,
  UTCDate,
  toISOStringWithoutOffset,
  toISOStringWithoutTime,
};
