import { ChangeDetectionStrategy, Component, inject, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  Entity,
  SelectDropdownItem,
  ToFormControls,
  USER_STATUSES_SELECT_ITEMS,
  UserStatuses,
} from '@rp/shared/models';
import {
  ButtonComponent,
  DialogComponent,
  DialogRef,
  IconName,
  InputComponent,
  SelectComponent,
  DIALOG_DATA,
  IntegerOnlyDirective,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { isFormEmpty } from '@rp/utils';

import { OffersDialogData, OffersFilterMappings, OffersFilter } from '../../../../models';
import { GeoSelectComponent } from '../../../geo-select';

@Component({
  selector: 'rp-offers-webmaster-mobile-filter',
  standalone: true,
  templateUrl: './offers-table-mobile-filter.component.html',
  styleUrl: './offers-table-mobile-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    DialogComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    TranslateModule,
    IntegerOnlyDirective,
    GeoSelectComponent,
  ],
})
export class OffersTableMobileFilterComponent implements OnInit {
  resetFilters = output<void>();

  form = new FormGroup<ToFormControls<OffersFilter>>({
    name: new FormControl(null),
    verticals: new FormControl(null),
    trafficSources: new FormControl(null),
    geos: new FormControl(null),
    paymentModel: new FormControl(null),
    cpaRateFrom: new FormControl(null),
    cpaRateTo: new FormControl(null),
    revsharePercentFrom: new FormControl(null),
    revsharePercentTo: new FormControl(null),
  });

  icons = IconName;
  isFormEmpty = isFormEmpty;

  trafficSources = signal<Entity[]>([]);
  verticals = signal<Entity[]>([]);
  geos = signal<Entity[]>([]);
  paymentModels = signal<Entity[]>([]);
  userStatuses = signal<SelectDropdownItem<UserStatuses>[]>(USER_STATUSES_SELECT_ITEMS);

  protected dialogData: OffersDialogData<OffersFilter, OffersFilterMappings> = inject(DIALOG_DATA);
  private _dialogRef = inject(DialogRef);

  ngOnInit(): void {
    this._populateData();
  }

  onClose(): void {
    this._dialogRef.close();
  }

  onResetFilters(): void {
    this.form.reset();
    this.resetFilters.emit();
  }

  onApplyFilters(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      return;
    }

    this._dialogRef.close(this.form.value);
  }

  private _populateData(): void {
    if (!this.dialogData) return;

    this.form.patchValue(this.dialogData.filter, { emitEvent: false });

    this.trafficSources.set(this.dialogData.filterMappings.trafficSources);
    this.verticals.set(this.dialogData.filterMappings.verticals);
    this.geos.set(this.dialogData.filterMappings.geos);
    this.paymentModels.set(this.dialogData.filterMappings.paymentModels);
  }
}
