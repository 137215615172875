import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { MessageSuccessRes, PresignedUrlReq, PresignedUrlRes } from '@rp/shared/models';

import { ImageUploaderProvider } from './image-uploader.provider';

@Injectable()
export class HttpImageUploaderProvider implements ImageUploaderProvider {
  private readonly _baseUrl = `${environment.apiConfig.adminApiHost}`;
  private readonly _presignedUrl = this._baseUrl + '/uploads/presigned-url/';
  private readonly _httpClient = inject(HttpClient);

  getImageFromS3(url: string): Observable<string> {
    return this._httpClient.get(url, { responseType: 'text' });
  }

  createPresignedUrl(payload: PresignedUrlReq): Observable<PresignedUrlRes> {
    let url = `${this._presignedUrl}?filePath=${payload.filePath}`;

    if (payload.expiration) {
      url = `${url}?expiration=${payload?.expiration}`;
    }

    return this._httpClient.get<PresignedUrlRes>(url);
  }

  saveImageToS3(presignedUrl: string, photoUri: string): Observable<MessageSuccessRes> {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');

    return this._httpClient.put<MessageSuccessRes>(presignedUrl, photoUri, { headers });
  }
}
