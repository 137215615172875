import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@rp/environments';

import { NotesProvider } from './notes.provider';
import { NotesResponse } from '../models/notes-response.interface';
import { AddNoteParams, GetNotesRequest } from '../models';

@Injectable()
export class HttpNotesProvider implements NotesProvider {
  private readonly _baseUrl = `${environment.apiConfig.adminApiHost}/admin/notes`;
  private readonly _httpClient = inject(HttpClient);

  getNotes(request: GetNotesRequest): Observable<NotesResponse> {
    return this._httpClient.get<NotesResponse>(`${this._baseUrl}/`, { params: { ...request } });
  }

  addNote(params: AddNoteParams): Observable<void> {
    return this._httpClient.post<void>(`${this._baseUrl}/`, { ...params });
  }
}
