import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { financesAmountMinValue } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const financesAmountMinValueValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.value && Number(control.value) < financesAmountMinValue
    ? { [Validation.FinancesAmountMinValue]: true }
    : null;
};
