<div class="image-upload">
  <div
    class="upload-container"
    [class]="size()"
    [ngClass]="{
      'has-image': imageSrc(),
      'full-width': fullWidth(),
      error: ngControl.control.invalid && (ngControl.control.dirty || ngControl.control.touched),
    }"
    (click)="fileInput.click()"
  >
    <input #fileInput type="file" (input)="onFileSelected($event)" accept="image/*" id="fileInput" hidden />
    <div
      class="upload-box"
      *rpSpinner="isLoading()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [ngClass]="{ dragging: isDragging }"
    >
      @if (imageSrc()) {
        <img class="image" [src]="imageSrc()" alt="Uploaded Image" />
        <rp-icon (click)="remove()" class="remove" [iconName]="icons.Remove" size="s" />
      } @else {
        <div>
          <rp-icon class="add-image-icon" [iconName]="icons.AddImage" size="l" />
          <p class="title">{{ title() | translate }}</p>
          <div class="instructions">
            <div class="description">{{ description() | translate }}</div>
            <div class="file-params-text">{{ fileParamsText() | translate: fileParams() }}</div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
