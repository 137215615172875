import { TableAction, TableColumn } from '@rp/shared/components';

import { OfferDescription, OffersRes, StreamsRes, OfferLandings } from '../../../models';

const offersTableColumns: TableColumn[] = [
  {
    columnDef: 'logo',
    header: 'general.logo',
    width: '72px',
  },
  {
    columnDef: 'id',
    header: 'general.id',
    withSort: true,
    width: '80px',
  },
  {
    columnDef: 'name',
    header: 'general.name',
    width: '265px',
  },
  {
    columnDef: 'geos',
    header: 'general.geo',
    width: '150px',
  },
  {
    columnDef: 'verticals',
    header: 'general.verticals',
    width: '200px',
  },
  {
    columnDef: 'sources',
    header: 'general.sources',
    width: '265px',
  },
  {
    columnDef: 'model',
    header: 'general.model',
    width: '95px',
  },
  {
    columnDef: 'isExclusive',
    header: '',
    width: '60px',
    align: 'center',
  },
  {
    columnDef: 'actions',
    header: 'general.actions',
    width: '60px',
    align: 'center',
    actions: [TableAction.Edit],
  },
];

const offersAdminTableColumns: TableColumn[] = [
  {
    columnDef: 'logo',
    header: 'general.logo',
  },
  {
    columnDef: 'id',
    header: 'general.id',
    withSort: true,
  },
  {
    columnDef: 'name',
    header: 'general.name',
  },
  {
    columnDef: 'geos',
    header: 'general.geo',
  },
  {
    columnDef: 'verticals',
    header: 'general.verticals',
  },
  {
    columnDef: 'sources',
    header: 'general.sources',
  },
  {
    columnDef: 'model',
    header: 'general.model',
  },
  {
    columnDef: 'rate$',
    header: 'offers.table.rateCurrency',
    width: '3%',
  },
  {
    columnDef: 'rate%',
    header: 'offers.table.revsharePercent',
    width: '3%',
  },
  {
    columnDef: 'isExclusive',
    header: '',
    width: '5%',
    align: 'center',
  },
  {
    columnDef: 'actions',
    header: 'general.actions',
    width: '5%',
    align: 'center',
    actions: [TableAction.Edit],
  },
];

const offers: OffersRes = {
  itemsCount: 4,
  offers: [
    {
      id: 0,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 1,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 2,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 3,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 4,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 5,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 6,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 7,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 8,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 9,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 10,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 11,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 12,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 13,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 14,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 15,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 16,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 17,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 18,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
    {
      id: 19,
      photoUri: '/',
      name: 'string',
      paymentModel: 'CPA',
      cpaRate: 0,
      revsharePercent: 0,
      isExclusive: true,
      geos: ['UK', 'RU', 'GE', 'UK', 'RU', 'GE'],
      verticals: [
        {
          id: 0,
          name: 'Gambling',
        },
      ],
      trafficSources: [
        {
          id: 0,
          name: 'CEO',
        },
        {
          id: 0,
          name: 'ASO',
        },
      ],
    },
  ],
};

const offerInfo: OfferDescription = {
  id: 0,
  registrationFieldCount: 12,
  capCount: 12,
  postbackDelay: 12,
  holdPeriodDays: 12,
  revsharePercent: 12,
  createLinkAllowedUserIds: [1, 2, 3],
  visibleAccessUserIds: [1, 2, 3],
  isExclusive: true,
  isHidden: false,
  photoUri: '',
  name: 'Name',
  paymentModel: {
    id: 1,
    name: 'cpa',
  },
  kpiSoft: 'kpiSoft',
  targetAudience: 'string',
  availablePaymentMethods: 'string',
  topSlots: 'string',
  bonus: 'string',
  recommendation: 'string',
  description: 'string',
  kpiHard: 'string',
  landings: [
    {
      photoUri: '',
      uri: '',
      name: 'Radio1',
    },
    {
      photoUri: '',
      uri: '',
      name: 'Radio2',
    },
    {
      photoUri: '',
      uri: '',
      name: 'Radio3',
    },
  ],
  verticals: [
    {
      id: 0,
      name: 'name',
    },
  ],
  trafficSources: [
    {
      id: 0,
      name: 'name',
    },
  ],
  availableLanguages: [
    {
      id: 1,
      code: 'ENG',
    },
    {
      id: 2,
      code: 'ESP',
    },
  ],
  geos: ['UK', 'RU'],
  events: [
    {
      id: 1,
      name: 'ENG',
    },
    {
      id: 1,
      name: 'ENG',
    },
  ],
  targetAction: {
    id: 1,
    name: 'Action',
  },
  cpaRate: 50,
  licenses: ['COM', 'COM2'],
};

const streams: StreamsRes = {
  offerStreams: [
    {
      id: 1,
      landing: 1,
      streamId: '213',
      link: 'router.rpartners.com/test/12/test/12/15',
      subId1: 'somesubname',
      subId2: 'somesubname',
      subId3: 'somesubname',
      subId4: 'somesubname',
      subId5: 'somesubname',
      createdAt: '4242342342342',
    },
    {
      id: 2,
      landing: 2,
      streamId: '213',
      link: 'router.rpartners.com/test/12/test/12/15',
      subId1: 'somesubname',
      subId2: 'somesubname',
      subId3: 'somesubname',
      subId4: 'somesubname',
      subId5: 'somesubname',
      createdAt: '4242342342342',
    },
    {
      id: 3,
      landing: 3,
      streamId: '213',
      link: 'router.rpartners.com/test/12/test/12/15',
      subId1: 'somesubname',
      subId2: 'somesubname',
      subId3: 'somesubname',
      subId4: 'somesubname',
      subId5: 'somesubname',
      createdAt: '4242342342342',
    },
    {
      id: 4,
      landing: 4,
      streamId: '213',
      link: 'router.rpartners.com/test/12/test/12/15',
      subId1: 'somesubname',
      subId2: 'somesubname',
      subId3: 'somesubname',
      subId4: 'somesubname',
      subId5: 'somesubname',
      createdAt: '4242342342342',
    },
    {
      id: 5,
      landing: 5,
      streamId: '213',
      link: 'router.rpartners.com/test/12/test/12/15',
      subId1: 'somesubname',
      subId2: 'somesubname',
      subId3: 'somesubname',
      subId4: 'somesubname',
      subId5: 'somesubname',
      createdAt: '4242342342342',
    },
  ],
  itemsCount: 5,
};

const directLinksTableColumns: TableColumn[] = [
  {
    columnDef: 'preview',
    header: 'general.labels.preview',
    width: '100px',
    maxWidth: '100px',
  },
  {
    columnDef: 'name',
    header: 'general.labels.name',
    width: '20%',
    withSort: true,
  },
  {
    columnDef: 'uri',
    header: 'general.labels.link',
    width: '30%',
    withSort: true,
  },
  {
    columnDef: 'priority',
    header: 'general.labels.priority',
    width: '100px',
    withSort: true,
    sortActive: true,
    direction: 'desc',
  },
  {
    columnDef: 'language_codes',
    header: 'general.labels.language',
    width: '5%',
    withSort: true,
  },
  {
    columnDef: 'action',
    header: 'general.labels.action',
    width: '170px',
    maxWidth: '170px',
    actions: [TableAction.Copy, TableAction.Edit, TableAction.Remove],
  },
];

const image =
  'https://www.shutterstock.com/shutterstock/photos/1821804134/display_1500/stock-photo-africa-tanzania-aerial-view-of-ol-doinyo-lengai-volcano-looming-above-vast-flock-of-lesser-1821804134.jpg';
const getLandingsMock = (items: number): any[] => {
  const list: any[] = [];

  for (let index = 0; index < items; index++) {
    list.push({
      id: 12345 + index,
      name: 'Some name',
      preview: image,
      dateAdded: '2024-11-04T00:00:00Z',
      vertical: 'test',
      platform: 'test',
      language: 'test',
    });
  }

  return list;
};

const activeLandingsTableColumns: TableColumn[] = [
  {
    columnDef: 'preview',
    header: 'general.labels.preview',
    width: '100px',
    maxWidth: '100px',
  },
  {
    columnDef: 'id',
    header: 'general.labels.id',
    width: '80px',
    maxWidth: '80px',
    withSort: true,
  },
  {
    columnDef: 'name',
    header: 'general.labels.name',
    width: '20%',
    withSort: true,
  },
  {
    columnDef: 'date_added',
    header: 'offers.promo.labels.dateAdded',
    width: '5%',
    withSort: true,
  },
  {
    columnDef: 'vertical_names',
    header: 'general.vertical',
    width: '5%',
    withSort: true,
  },
  {
    columnDef: 'platform',
    header: 'offers.promo.labels.platform',
    width: '5%',
    withSort: true,
  },
  {
    columnDef: 'language_codes',
    header: 'general.labels.language',
    width: '5%',
    withSort: true,
  },
  {
    columnDef: 'action',
    header: 'general.labels.action',
    width: '170px',
    maxWidth: '170px',
    actions: [TableAction.Edit, TableAction.View, TableAction.Remove],
  },
];

const landings: OfferLandings = {
  offerLandings: [
    {
      id: 1,
      name: 'Name',
      uri: 'link',
      photoUri: '',
      streams: [
        {
          id: 1,
          streamId: '1',
          link: '',
          createdAt: '',
          subId1: 'somesubname',
          subId2: 'somesubname',
          subId3: 'somesubname',
          subId4: 'somesubname',
          subId5: 'somesubname',
        },
        {
          id: 2,
          streamId: '2',
          link: '',
          createdAt: '',
          subId1: 'somesubname',
          subId2: 'somesubname',
          subId3: 'somesubname',
          subId4: 'somesubname',
          subId5: 'somesubname',
        },
      ],
    },
  ],
  itemsCount: 5,
};

export const OffersMock = {
  offersTableColumns,
  offersAdminTableColumns,
  directLinksTableColumns,
  offers,
  offerInfo,
  streams,
  landings,
  activeLandingsTableColumns,
  promoLandings: getLandingsMock(5),
};
