import { TableColumn } from '@rp/shared/components';

const DEFAULT_WIDTH = '120px';

export const ADMIN_FINANCE_TABLE_COLUMN: TableColumn[] = [
  {
    columnDef: 'id',
    header: 'finance.id',
    withSort: true,
    maxWidth: '70px',
    width: '70px',
  },
  {
    columnDef: 'webmasterId',
    header: 'finance.webmasterId',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'date',
    header: 'finance.date',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'method',
    header: 'finance.method',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'network',
    header: 'finance.network',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'walletAddress',
    header: 'finance.walletAddress',
    width: '356px',
  },
  {
    columnDef: 'status',
    header: 'finance.status',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'amount',
    header: 'finance.amount',
    withSort: true,
    sortActive: true,
    align: 'end',
    width: DEFAULT_WIDTH,
  },
  {
    columnDef: 'action',
    header: 'finance.action',
    align: 'center',
    width: '80px',
    maxWidth: '80px',
  },
];
