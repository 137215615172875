import { Component, TemplateRef, booleanAttribute, input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'rp-tab',
  standalone: true,
  template: '',
})
export class TabComponent<T> {
  readonly title = input.required<string>();
  readonly isDisabled = input(false);
  readonly isActive = input(false, { transform: booleanAttribute });
  readonly contentRef = input<TemplateRef<T>>();
  readonly actionsRef = input<TemplateRef<T>>();
  readonly value = input<T>();
  readonly queryParams = input<Params>();
}
