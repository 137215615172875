import { ErrorHandler, Injectable } from '@angular/core';

// first for webkit, second for chromium
const CHUNK_FAILED_MESSAGE_REGEX =
  /(Importing a module script failed|Failed to fetch dynamically imported module)/;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _angularErrorHandler = new ErrorHandler();

  handleError(error: any): void {
    const url = location.href;

    if (CHUNK_FAILED_MESSAGE_REGEX.test(error.message) && !url.includes('retry=')) {
      location.href = url + `${url.indexOf('?') > -1 ? '&' : '?'}retry=${Date.now()}`;

      return;
    }

    this._angularErrorHandler.handleError(error);
  }
}
