import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationDialogData } from './models/confirm-dialog-data.interface';
import { ButtonComponent } from '../button';
import { DIALOG_DATA, DialogComponent, DialogRef } from '../dialog';
import { IconComponent } from '../icon';

@Component({
  selector: 'rp-confirmation-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, DialogComponent, TranslateModule, IconComponent],
})
export class ConfirmationDialogComponent {
  data: ConfirmationDialogData = inject(DIALOG_DATA);

  private _dialogRef = inject(DialogRef);

  onClose(reason: boolean): void {
    this._dialogRef.close(reason);
  }
}
