import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { LazyLoadImageDirective } from './directives';

@Component({
  selector: 'rp-image',
  standalone: true,
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LazyLoadImageDirective],
})
export class ImageComponent {
  uri = input<string>('');
}
