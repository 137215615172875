import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true,
})
export class IncludesPipe implements PipeTransform {
  transform<T>(array: T[] | null | undefined, searchElement: T | T[], matchAll = false): boolean {
    if (!array) {
      return false;
    }

    if (!Array.isArray(searchElement)) {
      return array.includes(searchElement);
    }

    if (matchAll) {
      return searchElement.every(element => array.includes(element));
    } else {
      return searchElement.some(element => array.includes(element));
    }
  }
}
