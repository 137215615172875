import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

import {
  IconComponent,
  IconName,
  LazyLoadImageDirective,
  SpinnerDirective,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaceEmptyPipe, TransformStringListPipe } from '@rp/shared/pipes';
import { LogoComponent } from '@rp/shared/ui';
import { CommonMediaQueries, MediaScreenService } from '@rp/shared/services';

import { OfferDescription } from '../../../../models';

@Component({
  selector: 'rp-offer-description',
  standalone: true,
  templateUrl: './offer-description.component.html',
  styleUrl: './offer-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    SpinnerDirective,
    TranslateModule,
    TransformStringListPipe,
    ReplaceEmptyPipe,
    LogoComponent,
    NgOptimizedImage,
    LazyLoadImageDirective,
  ],
})
export class OfferDescriptionComponent implements OnInit {
  offerInfo = input.required<OfferDescription>();
  isLoading = computed(() => !this.offerInfo());

  _mediaScreenService = inject(MediaScreenService);

  isMobile = false;
  icons = IconName;

  readonly offerId = output<number>();

  private readonly _route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.isMobile = this._mediaScreenService.isMatched(CommonMediaQueries.MD, true);
    this._fetchOfferDescription();
  }

  private _fetchOfferDescription(): void {
    this.offerId.emit(this._route.snapshot.params['id']);
  }
}
