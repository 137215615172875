import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { PaginationAdapter } from '@rp/shared/adapters';

import { NotesProvider } from './notes.provider';
import { NotesResponse } from '../models/notes-response.interface';
import { NotesMock } from '../mocks/notes.mock';
import { AddNoteParams, GetNotesRequest, Note } from '../models';

@Injectable()
export class MockNotesProvider implements NotesProvider {
  getNotes(request: GetNotesRequest): Observable<NotesResponse> {
    const { items, pagination } = PaginationAdapter.fromMock(
      request,
      NotesMock.getNotesMock,
      (item: Note) => item,
    );

    return of({
      notes: items,
      itemsCount: pagination.itemsCount,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addNote(_params: AddNoteParams): Observable<void> {
    return of(null);
  }
}
