import { Note, NoteEntityType } from '../models';

const getNotesMock: Note[] = [
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
  {
    author: {
      fullName: 'Anastasiya Zhilinskaya',
      id: 1,
    },
    text: 'Need to change the date range to one date - since our event is displayed for a specific date (360 Total - https://www.figma.com/design/ptVR2KR6fAI1G7pTvizU2A/RP-Layouts?node-id=6442-205410&t=WXi2NOJJZwmRpPWO-4) Need to remove all information for advertiser, and leave only "Payouts" because everything else will not be there',
    createdAt: '2024-11-26T16:09:00',
    id: 1,
    objectEntityName: NoteEntityType.Webmasters,
    objectId: 1,
  },
];

export const NotesMock = {
  getNotesMock,
};
