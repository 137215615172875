import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { NotesResponse } from '../models/notes-response.interface';
import { AddNoteParams, GetNotesRequest } from '../models';

export interface NotesProvider {
  getNotes(request: GetNotesRequest): Observable<NotesResponse>;
  addNote(params: AddNoteParams): Observable<void>;
}

export const NOTES_PROVIDER_TOKEN = new InjectionToken<NotesProvider>('NotesProvider');
