import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';

import {
  ButtonComponent,
  InputComponent,
  SelectComponent,
  ToastService,
} from '@rp/shared/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpErrorHandlerService, PayoutsService, StructuresService } from '@rp/shared/services';
import { DEFAULT_PAGINATION, PaginationRequest, PaymentMethod } from '@rp/shared/models';
import { HttpPayoutsService, PAYOUTS_PROVIDER_TOKEN } from '@rp/shared/providers';
import { financesAmountMinValueValidator } from '@rp/shared/validators';

import { FinanceService } from '../../services/finance.service';

@Component({
  selector: 'rp-payout-card',
  standalone: true,
  templateUrl: './payout-card.component.html',
  styleUrl: './payout-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectComponent, ReactiveFormsModule, InputComponent, ButtonComponent, TranslateModule],
  providers: [
    { provide: PAYOUTS_PROVIDER_TOKEN, useClass: HttpPayoutsService },
    PayoutsService,
    CurrencyPipe,
  ],
})
export class PayoutCardComponent implements OnInit {
  form = new FormGroup({
    amountCents: new FormControl(null, [Validators.required, financesAmountMinValueValidator]),
    walletId: new FormControl(null, [Validators.required]),
  });

  paymentMethods = signal<PaymentMethod[]>([]);
  activePaymentMethods = computed<PaymentMethod[]>(() =>
    this.paymentMethods().filter(method => method.isActive),
  );

  getPayouts = output();

  activatedRoute = inject(ActivatedRoute);
  financeService = inject(FinanceService);
  httpErrorHandlerService = inject(HttpErrorHandlerService);
  structures = inject(StructuresService);

  private readonly _toast = inject(ToastService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _payoutsService = inject(PayoutsService);
  private readonly _httpErrorHandlerService = inject(HttpErrorHandlerService);
  private readonly _translateService = inject(TranslateService);
  private readonly _currencyPipe = inject(CurrencyPipe);

  ngOnInit(): void {
    this._getPayouts();
  }

  makePayout(): void {
    this.financeService
      .getInstance(this.activatedRoute.snapshot.data['target'])
      .makePayout({
        ...this.form.value,
        amountCents: this._toCents(Number(this.form.value.amountCents)),
      })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => {
          this._toast.showToast({
            text: this._translateService.instant('actions.toast.successfullyPayoutSubject', {
              method: this.activePaymentMethods()[0].walletName,
              amount: this._currencyPipe.transform(this.form.value.amountCents, 'USD'),
            }),
            type: 'success',
            icon: '',
            title: 'actions.toast.successfullyPayoutTitle',
          });
          this.form.reset();
          this.getPayouts.emit();
        },
        error: (error: HttpErrorResponse) => this._httpErrorHandlerService.showErrorMessage(error),
      });
  }

  onScroll(): void {
    if (this.paymentMethods().length % 5 === 0) {
      this._getPayouts({ page: 1, limit: this.paymentMethods().length + 5 });
    }
  }

  private _getPayouts(pagination?: PaginationRequest): void {
    this._payoutsService
      .getPayouts(pagination ?? DEFAULT_PAGINATION)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: ({ payoutMethod }) => this.paymentMethods.set(payoutMethod),
        error: (error: HttpErrorResponse) => this._httpErrorHandlerService.showErrorMessage(error),
      });
  }

  private _toCents(value: number): number {
    return value * 100;
  }
}
