@let isMobile = isMobile$ | async;

<rp-dialog [caption]="'finance.viewDetails' | translate" [iconSize]="isMobile ? 's' : 'm'" (closeDialog)="dialogRef.close()">
  <ng-container dialogBody *rpSpinner="isLoading()">
    @if (dialogData.target === targetApp.Webmaster) {
      <div class="header-title">
        <span class="header-title__amount">{{ 'finance.amount' | translate }}</span>
        <span class="header-title__value">{{ dialogData.payout.amountCents | centsToDollarsPipe }}</span>
      </div>
    }
    <div class="table">
      @if (!isMobile) {
        <div class="header">
          <div class="header__date">{{ 'finance.dateTime' | translate }}</div>
          <div class="header__status">{{ 'finance.status' | translate }}</div>
          <div class="header__comment">{{ 'finance.comment' | translate }}</div>
        </div>
      }
      @for (item of items(); track $index) {
        <div class="card">
          <div class="card__row">
            <div class="card__row-column">
              <div class="card__row-column-date">{{ item.date | date: DateFormat.ShortDate }}</div>
              <div class="card__row-column-time">{{ item.date | date: DateFormat.TimeWithSeconds }}</div>
            </div>
            <div class="card__row-column">
              <div class="card__row-column-direction">{{ 'finance.changedTo' | translate }}</div>
            </div>
            <div class="card__row-column">
              <rp-finance-status [status]="item.status" />
            </div>
            <div class="card__row-column card__row-column-comment">{{ item.comment }}</div>
          </div>
        </div>
      }
    </div>
  </ng-container>
  <ng-container dialogFooter>
    @if (dialogData.target === targetApp.Admin) {
      <rp-button (click)="changeStatus()" [disabled]="isStatusChangeless()">
        {{ 'finance.changeStatus' | translate }}
      </rp-button>
    }
  </ng-container>
</rp-dialog>
