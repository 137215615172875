import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map, Observable } from 'rxjs';
import { MediaQueries } from '@rp/shared/models';
import { HeaderStateService } from '@rp/shared/services';

export interface Breakpoints {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

@Component({
  selector: 'rp-shell-base',
  template: '',
})
export abstract class ShellBaseComponent implements OnInit {
  breakpoints$: Observable<Breakpoints>;

  headerStateService = inject(HeaderStateService);

  private _destroyRef = inject(DestroyRef);
  private _breakpointObserver = inject(BreakpointObserver);
  private _router = inject(Router);

  ngOnInit(): void {
    this.breakpoints$ = this._getBreakpoints();

    this.headerStateService.setDataFromRoute();

    this._subscribeToRouterEvents();
  }

  private _subscribeToRouterEvents(): void {
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.headerStateService.reset();
        this.headerStateService.setDataFromRoute();
      });
  }

  private _getBreakpoints(): Observable<Breakpoints> {
    return this._breakpointObserver
      .observe([MediaQueries.Mobile, MediaQueries.Tablet, MediaQueries.Desktop])
      .pipe(
        map(({ breakpoints }) => {
          return {
            isMobile: breakpoints[MediaQueries.Mobile],
            isTablet: breakpoints[MediaQueries.Tablet],
            isDesktop: breakpoints[MediaQueries.Desktop],
          };
        }),
      );
  }
}
