import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { isFormEmpty } from '@rp/utils';
import {
  ButtonComponent,
  IconName,
  InputComponent,
  IntegerOnlyDirective,
  SelectComponent,
} from '@rp/shared/components';
import {
  Entity,
  SelectDropdownItem,
  ToFormControls,
  USER_STATUSES_SELECT_ITEMS,
  UserStatuses,
} from '@rp/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { OffersFilterMappings, OffersFilter } from '../../../../models';
import { GeoSelectComponent } from '../../../geo-select';

@Component({
  selector: 'rp-offers-table-filters',
  standalone: true,
  templateUrl: './offers-table-filters.component.html',
  styleUrl: './offers-table-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    ButtonComponent,
    TranslateModule,
    IntegerOnlyDirective,
    GeoSelectComponent,
  ],
})
export class OffersTableFiltersComponent {
  filter = input.required<OffersFilter | null>();
  filterMappings = input.required<OffersFilterMappings>();
  filterForm = input.required<FormGroup<ToFormControls<OffersFilter>>>();
  shouldShowRates = input<boolean>(false);

  filterChanged = output<OffersFilter>();

  icons = IconName;
  isFormEmpty = isFormEmpty;

  trafficSources = signal<Entity[]>([]);
  verticals = signal<Entity[]>([]);
  geos = signal<Entity[]>([]);
  paymentModels = signal<Entity[]>([]);
  userStatuses = signal<SelectDropdownItem<UserStatuses>[]>(USER_STATUSES_SELECT_ITEMS);

  constructor() {
    effect(
      () => {
        if (this.filter() && Object.values(this.filter()).length) {
          this.filterForm().patchValue(this.filter(), { emitEvent: false });
        } else {
          this.onResetFilters();
        }

        if (this.filterMappings()) {
          this.trafficSources.set(this.filterMappings().trafficSources);
          this.verticals.set(this.filterMappings().verticals);
          this.geos.set(this.filterMappings().geos);
          this.paymentModels.set(this.filterMappings().paymentModels);
        }
      },
      { allowSignalWrites: true },
    );
  }

  onResetFilters(): void {
    this.filterForm().reset();

    this.filterChanged.emit(<OffersFilter>this.filterForm().value);
  }

  onApplyFilters(): void {
    if (this.filterForm().invalid) {
      this.filterForm().markAllAsTouched();

      return;
    }

    this.filterChanged.emit(<OffersFilter>this.filterForm().value);
  }
}
