import {
  PaginationRestResponse,
  PaginationResponse,
  PaginationRequest,
  PaginationRestRequest,
} from '@rp/shared/models';

const fromRest = ({ itemsCount }: PaginationRestResponse): PaginationResponse => ({
  itemsCount,
});

const toRest = ({ limit, page }: PaginationRequest): PaginationRestRequest => ({
  limit,
  page,
});

const fromMock = <T, K>(
  { page, limit }: PaginationRequest,
  items: T[],
  callback: (item: T) => K,
): { items: K[]; pagination: PaginationResponse } => {
  const from: number = page === 1 ? 0 : (page - 1) * limit;
  const to: number = page === 1 ? limit : page * limit;

  return {
    items: items.map(item => callback(item)).slice(from, to),
    pagination: {
      itemsCount: items.length,
    },
  };
};

export const PaginationAdapter = {
  fromRest,
  toRest,
  fromMock,
};
