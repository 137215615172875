<rp-card>
  <div class="header-wrapper" [class.has-image]="image()" cardHeader>
    @if (image()) {
      <img [rpLazyLoadImage]="image()" />
    } @else {
      <div class="no-preview">
        {{ 'general.noPreviewImage' | translate }}
      </div>
    }
  </div>
  <div cardContent class="content-wrapper">
    <span class="identification">{{ 'general.id' | translate }}: {{ id() }}</span>
    <span class="name">{{ name() }}</span>
    <span>{{ 'general.language' | translate }}: {{ languages().join(', ') }}</span>
  </div>

  <rp-button fullWidth fullHeight cardActions (click)="tileAction.emit()">
    {{ buttonName() }}
  </rp-button>
</rp-card>
