<rp-dialog [caption]="data?.title || 'dialogs.confirmDelete' | translate" size="s" (closeDialog)="onClose(false)">
  <ng-container dialogBody>
    <div class="body">
      @if (data?.icon) {
        <rp-icon size="3xl" [iconName]="data.icon"></rp-icon>
      }
      <p class="message">{{ data?.message || 'dialogs.confirmDeleteMessage' | translate }}</p>
      @if (data?.subMessage) {
        <p class="sub-message">{{ data.subMessage | translate }}</p>
      }
    </div>
  </ng-container>
  <ng-container dialogFooter>
    <div class="actions">
      <rp-button fullWidth (click)="onClose(true)">
        {{ data?.confirmLabel || 'dialogs.remove' | translate }}
      </rp-button>
      <rp-button fullWidth type="secondary" (click)="onClose(false)">{{ data?.cancelLabel || 'dialogs.cancel' | translate }}</rp-button>
    </div>
  </ng-container>
</rp-dialog>
