import { Injectable } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { MENU_LEAVE_TIMEOUT, TRIGGER_LEAVE_TIMEOUT } from '@rp/shared/models';
import { IconName } from '@rp/shared/components';
import { environment, SidebarNavItemsType } from '@rp/environments';

import { MenuListItem, OpenedMenus } from '../models';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private _setTimeoutIds: ReturnType<typeof setTimeout>[] = [];
  private _openedMenus = new Map<string, OpenedMenus>();

  getMenuItems(): MenuListItem[] {
    return [
      {
        icon: IconName.Finance,
        label: 'menu.finance',
        shouldShow: this._shouldShowMenuItem('finance'),
        link: 'finance',
      },
      {
        icon: IconName.Statistic,
        label: 'menu.statistic',
        shouldShow: this._shouldShowMenuItem('adminStatistic'),
        link: 'statistic',
      },
      {
        icon: IconName.Statistic,
        label: 'menu.statistic',
        shouldShow: this._shouldShowMenuItem('statistic'),
        link: 'statistic',
      },
      {
        icon: IconName.MyOffersVip,
        label: 'menu.partners',
        shouldShow: this._shouldShowMenuItem('partners'),
        link: 'partners',
      },
      {
        icon: IconName.MyOffersVip,
        label: 'menu.myOffers',
        shouldShow: this._shouldShowMenuItem('myOffers'),
        link: 'my-offers',
      },
      {
        icon: IconName.Offerwall,
        label: 'menu.offersWall',
        shouldShow: this._shouldShowMenuItem('offersWall'),
        link: 'offers-wall',
      },
      {
        icon: IconName.LandingPage,
        label: 'menu.promo',
        shouldShow: this._shouldShowMenuItem('promo'),
        link: 'promo',
      },
      {
        icon: IconName.Instruments,
        label: 'menu.instruments',
        shouldShow: this._shouldShowMenuItem('instruments'),
        link: 'instruments',
      },
      {
        icon: IconName.Infrastr,
        label: 'menu.infrastructure',
        shouldShow: this._shouldShowMenuItem('infrastructure'),
        link: 'infrastructure',
      },
      {
        icon: IconName.Shop,
        label: 'menu.shop',
        shouldShow: this._shouldShowMenuItem('shop'),
        link: 'shop',
      },
    ];
  }

  getFooterMenuItems(): MenuListItem[] {
    return [
      {
        icon: IconName.Rbac,
        label: 'menu.rbac',
        shouldShow: this._shouldShowMenuItem('rbac'),
        link: 'rbac',
      },
    ];
  }

  getSocialItems(): MenuListItem[] {
    return [
      {
        icon: IconName.LinkedIn,
        label: 'linkedIn',
        url: '',
      },
      {
        icon: IconName.Instagram,
        label: 'instagram',
        url: '',
      },
      {
        icon: IconName.Telegram,
        label: 'telegram',
        url: '',
      },
      {
        icon: IconName.Twitter,
        label: 'twitter',
        url: '',
      },
    ];
  }

  onTriggerEnter(trigger: MatMenuTrigger): void {
    trigger.openMenu();

    this._openedMenus.set(trigger.menu.panelId, {
      hasOpenedMenu: true,
      isOnMenu: false,
      isOnTrigger: true,
      trigger,
    });
  }

  onTriggerLeave(trigger: MatMenuTrigger): void {
    this._clearTimeouts();

    const panelId = trigger.menu.panelId;

    if (this._openedMenus.get(panelId)) {
      this._openedMenus.set(panelId, {
        ...this._openedMenus.get(panelId),
        isOnTrigger: false,
      });
    }

    this._setTimeoutIds.push(
      setTimeout(() => {
        const currentTrigger = this._openedMenus.get(panelId);

        if (currentTrigger && currentTrigger.hasOpenedMenu && !currentTrigger.isOnMenu) {
          trigger.closeMenu();

          this._openedMenus.delete(panelId);
        }
      }, TRIGGER_LEAVE_TIMEOUT),
    );
  }

  onMenuEnter(trigger: MatMenuTrigger): void {
    this._openedMenus.set(trigger.menu.panelId, {
      ...this._openedMenus.get(trigger.menu.panelId),
      isOnMenu: true,
    });
  }

  onMenuLeave(trigger: MatMenuTrigger): void {
    this._setTimeoutIds.push(
      setTimeout(() => {
        const panelId = trigger.menu.panelId;

        this._openedMenus.set(panelId, {
          ...this._openedMenus.get(panelId),
          isOnMenu: false,
        });

        if (!this._openedMenus.get(panelId).isOnTrigger) {
          const convertedOpenedMenus = Array.from(this._openedMenus.values());

          const menuTriggerIndex = convertedOpenedMenus.findIndex(
            triggerItem => triggerItem.isOnMenu,
          );

          // Check if there is a trigger that is on menu
          if (menuTriggerIndex !== -1) {
            for (let i = menuTriggerIndex; i < convertedOpenedMenus.length; i++) {
              const triggerItem = convertedOpenedMenus[i];

              if (!triggerItem.isOnMenu && !triggerItem.isOnTrigger) {
                triggerItem.trigger.closeMenu();

                this._openedMenus.delete(triggerItem.trigger.menu.panelId);
              }
            }
          } else {
            this._openedMenus.forEach(triggerItem => {
              if (!triggerItem.isOnTrigger) {
                triggerItem.trigger.closeMenu();

                this._openedMenus.delete(triggerItem.trigger.menu.panelId);
              }
            });
          }
        }
      }, MENU_LEAVE_TIMEOUT),
    );
  }

  private _clearTimeouts(): void {
    if (this._setTimeoutIds.length) {
      this._setTimeoutIds.forEach(clearTimeout);

      this._setTimeoutIds = [];
    }
  }

  private _shouldShowMenuItem(name: SidebarNavItemsType): boolean {
    return environment.uiConfig.sidebar.includes(name);
  }
}
