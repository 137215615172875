import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  model,
  OnInit,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FieldErrorStateMatcher } from '@rp/utils';
import { IconComponent, SelectComponent, SpinnerComponent } from '@rp/shared/components';

import { CountriesService } from '../../services';

const templateUrl = '../../../../../shared/components/src/lib/select/select.component.html';
const styleUrl = '../../../../../shared/components/src/lib/select/select.component.scss';

@Component({
  selector: 'rp-geo-select',
  standalone: true,
  templateUrl,
  styleUrl,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    IconComponent,
    TranslateModule,
    SpinnerComponent,
    NgTemplateOutlet,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: ErrorStateMatcher, useClass: FieldErrorStateMatcher },
  ],
})
export class GeoSelectComponent<T> extends SelectComponent<T> implements OnInit {
  override items = model.required<T[] | null>();
  override subscriptSizing = input<SubscriptSizing>('dynamic');
  override displayField = input<keyof T>('label' as keyof T);
  override selectedField = input<keyof T>('name' as keyof T);
  override multiple = input(true, {
    transform: booleanAttribute,
  });
  override searchable = input(true, {
    transform: booleanAttribute,
  });
  override showCountryIcons = input(true, {
    transform: booleanAttribute,
  });
  override showCustomLabel = input(true, {
    transform: booleanAttribute,
  });

  private _skipEffect = false;

  private readonly _countriesService = inject(CountriesService);
  private readonly _translate = inject(TranslateService);

  constructor() {
    super();

    effect(
      () => {
        if (!this._skipEffect) {
          this._translateCountryNames();
        }
      },
      { allowSignalWrites: true },
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this._countriesService.localeRegistered
      .pipe(takeUntilDestroyed(this['_destroyRef']))
      .subscribe(() => {
        this._translateCountryNames();
      });
  }

  private _translateCountryNames(): void {
    if (this.items()?.length) {
      this._skipEffect = true;

      this.items.update(items => {
        return items?.map(item => {
          const countryName = this._countriesService.getName(
            item[this.selectedField() as keyof T] as string,
            this._translate.currentLang.toLocaleLowerCase(),
          );

          return {
            ...item,
            [this.displayField()]: countryName,
          };
        });
      });
    }
  }
}
