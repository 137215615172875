import { EventEmitter, Injectable } from '@angular/core';

import * as countries from 'i18n-iso-countries';

@Injectable({ providedIn: 'root' })
export class CountriesService {
  localeRegistered = new EventEmitter<void>();

  async registerLocale(language: string): Promise<void> {
    const module = await import(
      `../../../../../node_modules/i18n-iso-countries/langs/${language.toLocaleLowerCase()}.json`
    );

    countries.registerLocale(module.default);

    this.localeRegistered.emit();
  }

  getName(
    countryCode: string | number | countries.Alpha2Code | countries.Alpha3Code,
    language: string,
  ): string | undefined {
    return countries.getName(countryCode, language);
  }
}
