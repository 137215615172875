import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';

import { RadioButton } from './models';
import { IconComponent } from '../icon';
import { TooltipDirective } from '../tooltip';

@Component({
  selector: 'rp-radio',
  standalone: true,
  templateUrl: './radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
  imports: [
    IconComponent,
    MatRadioButton,
    MatRadioGroup,
    NgClass,
    ReactiveFormsModule,
    TooltipDirective,
  ],
})
export class RadioComponent<T> {
  /**
   * Radio buttons list
   */
  radioButtons = input.required<RadioButton<T>[]>();

  /**
   * Label position for radio buttons
   */
  labelPosition = input<'before' | 'after'>('after');

  /**
   * If radio buttons has disabled state
   */
  disabled = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If radio buttons is required
   */
  required = input(false, {
    transform: booleanAttribute,
  });

  /**
   * Radio buttons group name, used to group radio buttons
   */
  groupName = input<string>();

  /**
   * Icon name for radio buttons
   */
  iconName = input<string>();

  /**
   * Icon tooltip message for icon
   */
  iconTooltipMessage = input<string>();

  /**
   * Show icon in tooltip or not
   */
  showIconInTooltip = input<boolean>();

  /**
   * If icon is clickable
   */
  isClickableIcon = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If icon is clickable, iconClick should trigger value to outer container
   */
  iconClick = output<RadioButton<T>>();

  ngControl = injectNgControl();

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.required()) {
        control.setValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }

      if (this.disabled()) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }
}
