import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  booleanAttribute,
  effect,
  inject,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  NgModel,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import {
  ExtractDateTypeFromSelection,
  MatDatepickerInputEvent,
  MatDatepickerModule,
  MatDateRangeInput,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateMatcher } from '@angular/material/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FieldErrorStateMatcher, injectNgControl } from '@rp/utils';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { DateRange } from '@rp/shared/models';
import { IncludesPipe, ObjectKeysToArrayPipe } from '@rp/shared/pipes';
import { Validation } from '@rp/shared/validators';

import { IconComponent, IconName } from '../icon';
import { DatePickerHeaderComponent } from './date-picker-header';

@Component({
  selector: 'rp-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    IconComponent,
    TranslateModule,
    IncludesPipe,
    ObjectKeysToArrayPipe,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: ErrorStateMatcher, useClass: FieldErrorStateMatcher },
  ],
})
export class DatePickerComponent implements OnInit {
  dateRangeInput = viewChild(MatDateRangeInput);

  maxDate = input<Date | null>(new Date());
  label = input<string>('');
  hint = input<string>('');
  placeholder = input<string>('');
  placeholderStart = input<string>('');
  placeholderEnd = input<string>('');
  startDateKey = input<string>('startDate');
  endDateKey = input<string>('endDate');
  subscriptSizing = input<SubscriptSizing>('fixed');
  matcher = input<FieldErrorStateMatcher>();
  isDisabled = input(false, {
    transform: booleanAttribute,
  });
  isRequired = input(false, {
    transform: booleanAttribute,
  });
  showErrorMessage = input(true, {
    transform: booleanAttribute,
  });
  clearable = input(false, {
    transform: booleanAttribute,
  });

  onChange = output<MatDatepickerInputEvent<ExtractDateTypeFromSelection<DateRange>>>();

  hasRange = false;

  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();
  startControl = new FormControl<Date | null>(null);
  endControl = new FormControl<Date | null>(null);
  datePickerHeader = DatePickerHeaderComponent;
  icons = IconName;

  readonly patternValidations: Validation[] = [
    Validation.DatePickerParse,
    Validation.DatePickerMax,
  ];

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _timeOut: ReturnType<typeof setTimeout>;

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.isDisabled()) {
        control.disable({ emitEvent: false });
        this.startControl.disable({ emitEvent: false });
        this.endControl.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
        this.startControl.enable({ emitEvent: false });
        this.endControl.enable({ emitEvent: false });
      }

      if (this.isRequired() || this.ngControl.control.errors?.['required']) {
        control.setValidators(Validators.required);
        this.startControl.setValidators(Validators.required);
        this.endControl.setValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
        this.startControl.removeValidators(Validators.required);
        this.endControl.removeValidators(Validators.required);
      }

      if (this.placeholderStart() || this.placeholderEnd()) {
        // Trigger change detection to update the view when the placeholder changes
        this._updateDateRangeInputView();
      }
    });
  }

  ngOnInit(): void {
    this._setRangeControlsValues();

    this.ngControl.valueChanges?.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(e => {
      if (e === null) {
        this.startControl.setValue(null, { emitEvent: false });
        this.endControl.setValue(null, { emitEvent: false });
      } else {
        if (this.startControl.value !== e?.[this.startDateKey()]) {
          this.startControl.setValue(e?.[this.startDateKey()], { emitEvent: false });
        }

        if (this.endControl.value !== e?.[this.endDateKey()]) {
          this.endControl.setValue(e?.[this.endDateKey()], { emitEvent: false });
        }
      }
    });
  }

  onClose(): void {
    clearTimeout(this._timeOut);
  }

  onClear(): void {
    this.ngControl.control.reset();
    this.onChange.emit(null);
  }

  onStartDateChange({
    value,
  }: MatDatepickerInputEvent<ExtractDateTypeFromSelection<DateRange>>): void {
    this.ngControl.control.setValue({
      ...this.ngControl.control.value,
      [this.startDateKey()]: value,
    });
    this.ngControl.control.setErrors(this.startControl.errors);

    this.onChange.emit(this.ngControl.value);

    this._updateDateRangeInputView();
  }

  onEndDateChange({
    value,
  }: MatDatepickerInputEvent<ExtractDateTypeFromSelection<DateRange>>): void {
    this.ngControl.control.setValue({
      ...this.ngControl.control.value,
      [this.endDateKey()]: value,
    });
    this.ngControl.control.setErrors(this.endControl.errors);

    this.onChange.emit(this.ngControl.value);

    this._updateDateRangeInputView();
  }

  private _setRangeControlsValues(): void {
    this.hasRange =
      !!this.ngControl.control.value && !!Object.keys(this.ngControl.control.value).length;
    if (this.hasRange && this.startDateKey() && this.endDateKey()) {
      this.startControl.setValue(this.ngControl.value?.[this.startDateKey()]);
      this.endControl.setValue(this.ngControl.value?.[this.endDateKey()]);
    }
  }

  private _updateDateRangeInputView(): void {
    if (this.dateRangeInput()) {
      this.dateRangeInput()['_changeDetectorRef'].markForCheck();
    }
  }
}
