import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { UserStatuses, WebmasterRest, WebmastersResponse } from '@rp/shared/models';
import { PaginationAdapter, WebmastersAdapter } from '@rp/shared/adapters';

import { WebmastersProvider } from './webmasters.provider';

const getWebmastersMock = (items: number): WebmasterRest[] => {
  const list: WebmasterRest[] = [];

  for (let index = 0; index < items; index++) {
    list.push({
      id: 12345 + index,
      fullName: 'Some name',
      email: 'someemail@gmail.com',
      trafficSources: [{ id: 1, name: 'Some source' }],
      verticals: [{ id: 1, name: 'Some vertical' }],
      conversions: 1,
      status: UserStatuses.New,
      managerId: 123,
      messengerId: 1,
      nickname: 'test',
      notes: 'Some notes',
    });
  }

  return list;
};

@Injectable()
export class MockWebmastersProvider implements WebmastersProvider {
  getWebmasters(params: string): Observable<WebmastersResponse> {
    const searchParams = new URLSearchParams(params);

    const page = Number(searchParams.get('page'));
    const limit = Number(searchParams.get('limit'));

    const { items, pagination } = PaginationAdapter.fromMock(
      { page, limit },
      getWebmastersMock(22),
      WebmastersAdapter.fromRestWebmaster,
    );

    return of({
      webmasters: items,
      itemsCount: pagination.itemsCount,
    });
  }
}
