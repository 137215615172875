@if (hasRange) {
  <mat-form-field
    [subscriptSizing]="subscriptSizing()"
    [class.filled]="!!startControl.value?.toString().length || !!endControl.value?.toString().length"
    appearance="outline"
    class="rp-date-picker"
  >
    <mat-label>{{ label() }}</mat-label>

    <mat-date-range-input [max]="maxDate()" [rangePicker]="picker" [disabled]="isDisabled()">
      <input matStartDate [formControl]="startControl" (dateChange)="onStartDateChange($event)" [placeholder]="placeholderStart()" />
      <input matEndDate [formControl]="endControl" (dateChange)="onEndDateChange($event)" [placeholder]="placeholderEnd()" />
    </mat-date-range-input>

    @if (hint()) {
      <mat-hint>{{ hint() }}</mat-hint>
    }

    <div class="rp-date-picker-suffix" matSuffix>
      @if (clearable() && (startControl.value || endControl.value)) {
        <rp-icon class="clear-icon" [iconName]="icons.ResetFilter" size="s" (click)="onClear()" />
      }

      <mat-datepicker-toggle [for]="picker" [disableRipple]="true">
        <rp-icon class="calendar-icon" [iconName]="icons.Calendar" size="s" matDatepickerToggleIcon />
      </mat-datepicker-toggle>
    </div>

    <mat-date-range-picker #picker [calendarHeaderComponent]="datePickerHeader" (closed)="onClose()" />

    @if (showErrorMessage()) {
      @let startControlErrors = startControl.errors | objectKeysToArray;
      @let endControlErrors = endControl.errors | objectKeysToArray;

      @if ((startControlErrors | includes: patternValidations) || (endControlErrors | includes: patternValidations)) {
        <mat-error>{{ 'validations.pattern' | translate }}</mat-error>
      } @else if (startControl.hasError('required') || endControl.hasError('required')) {
        <mat-error>{{ 'validations.required' | translate }}</mat-error>
      }
    }
  </mat-form-field>
} @else {
  <mat-form-field
    [subscriptSizing]="subscriptSizing()"
    [class.filled]="!!ngControl.control.value?.toString().length"
    appearance="outline"
    class="rp-date-picker"
  >
    <mat-label>{{ label() }}</mat-label>
    <input
      [max]="maxDate()"
      matInput
      [matDatepicker]="picker"
      [formControl]="ngControl.control"
      [errorStateMatcher]="matcher()"
      [placeholder]="placeholder()"
      [disabled]="isDisabled()"
      (dateChange)="onChange.emit($event)"
    />
    @if (hint()) {
      <mat-hint>{{ hint() }}</mat-hint>
    }

    <div class="rp-date-picker-suffix" matSuffix>
      @if (clearable() && (startControl.value || endControl.value)) {
        <rp-icon class="calendar-icon" [iconName]="icons.ResetFilter" size="s" (click)="onClear()" />
      }

      <mat-datepicker-toggle [for]="picker" [disableRipple]="true">
        <rp-icon class="calendar-icon" [iconName]="icons.Calendar" size="s" matDatepickerToggleIcon />
      </mat-datepicker-toggle>
    </div>

    <mat-datepicker #picker />

    @if (ngControl.control.hasError('required') && ngControl.dirty && showErrorMessage()) {
      <mat-error>{{ 'validations.required' | translate }}</mat-error>
    }
  </mat-form-field>
}
