import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeysToArray',
  standalone: true,
})
export class ObjectKeysToArrayPipe implements PipeTransform {
  transform<T extends object>(value: T): Array<keyof T> {
    if (!value) {
      return [];
    }

    return Object.keys(value) as Array<keyof T>;
  }
}
