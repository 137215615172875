import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  signal,
  computed,
  inject,
  DestroyRef,
  effect,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[rpDynamicTitle]',
  standalone: true,
})
export class DynamicTitleDirective implements OnInit, OnDestroy {
  private readonly _resizeSubject = new Subject<void>();
  private _resizeObserver: ResizeObserver;

  private readonly _containerWidth = signal<number>(0);
  private readonly _contentWidth = signal<number>(0);

  private readonly _isOverflowing = computed(() => this._contentWidth() > this._containerWidth());

  private readonly _elementRef = inject(ElementRef);
  private readonly _renderer = inject(Renderer2);
  private readonly _destroyRef = inject(DestroyRef);

  constructor() {
    this._setElementTitle();
  }

  ngOnInit(): void {
    this._initResizeObserver();
    this._initResizeSubject();
    this._updateDimensions();
  }

  ngOnDestroy(): void {
    this._resizeObserver?.disconnect();
  }

  private _initResizeObserver(): void {
    const element = this._elementRef.nativeElement;

    this._resizeObserver = new ResizeObserver(() => {
      this._resizeSubject.next();
    });

    this._resizeObserver.observe(element);
  }

  private _initResizeSubject(): void {
    this._resizeSubject.pipe(debounceTime(200), takeUntilDestroyed(this._destroyRef)).subscribe({
      next: this._updateDimensions.bind(this),
    });
  }

  private _updateDimensions(): void {
    const element = this._elementRef.nativeElement;
    const newContainerWidth = element.clientWidth;
    const newContentWidth = element.scrollWidth;

    if (this._containerWidth() !== newContainerWidth) {
      this._containerWidth.set(newContainerWidth);
    }

    if (this._contentWidth() !== newContentWidth) {
      this._contentWidth.set(newContentWidth);
    }
  }

  private _setElementTitle(): void {
    effect(() => {
      const element = this._elementRef.nativeElement;

      if (this._isOverflowing()) {
        this._renderer.setAttribute(element, 'title', element.textContent || '');
      } else {
        this._renderer.removeAttribute(element, 'title');
      }
    });
  }
}
