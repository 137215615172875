@let isFormValueEmpty = isFormEmpty(filterForm().value);

<div class="table-filters">
  <div class="form__wrapper">
    <form [formGroup]="filterForm()">
      <div class="row">
        <rp-input
          class="rp-input"
          formControlName="name"
          [iconName]="icons.Search"
          [label]="'general.name' | translate"
          [placeholder]="'general.search' | translate"
        />
        <rp-select
          formControlName="trafficSources"
          [items]="trafficSources()"
          multiple
          subscriptSizing="dynamic"
          selectedField="id"
          [label]="'general.sources' | translate"
          [placeholder]="'general.select' | translate"
          displayField="name"
        />
        <rp-select
          formControlName="verticals"
          [items]="verticals()"
          subscriptSizing="dynamic"
          selectedField="id"
          multiple
          [label]="'general.verticals' | translate"
          [placeholder]="'general.select' | translate"
          displayField="name"
        />
        <rp-geo-select
          formControlName="geos"
          [items]="geos()"
          [label]="'general.geo' | translate"
          [placeholder]="'general.placeholders.select' | translate"
        />
      </div>
      <div class="row">
        <rp-select
          formControlName="paymentModel"
          [items]="paymentModels()"
          subscriptSizing="dynamic"
          [placeholder]="'general.model' | translate"
          selectedField="id"
          [label]="'general.model' | translate"
          multiple
          displayField="name"
        />
        @if (shouldShowRates()) {
          <div class="rates__currencies">
            <rp-input
              class="rp-input"
              rpIntegerOnly
              type="number"
              formControlName="cpaRateFrom"
              [label]="'offers.table.rateCurrencyFrom' | translate"
              [placeholder]="'general.from' | translate"
            />
            <span class="divider">-</span>
            <rp-input
              class="rp-input"
              rpIntegerOnly
              type="number"
              formControlName="cpaRateTo"
              [label]="'offers.table.rateCurrencyTo' | translate"
              [placeholder]="'general.to' | translate"
            />
          </div>
          <div class="rates__percents">
            <rp-input
              class="rp-input"
              rpIntegerOnly
              type="number"
              formControlName="revsharePercentFrom"
              [label]="'offers.table.revsharePercentFrom' | translate"
              [placeholder]="'general.from' | translate"
            />
            <span class="divider">-</span>
            <rp-input
              class="rp-input"
              rpIntegerOnly
              type="number"
              formControlName="revsharePercentTo"
              [label]="'offers.table.revsharePercentTo' | translate"
              [placeholder]="'general.to' | translate"
            />
          </div>
        }
        <rp-button type="secondary" class="apply-button" [disabled]="isFormValueEmpty" (click)="onApplyFilters()">
          {{ 'general.buttons.applyFilters' | translate }}
        </rp-button>
      </div>
    </form>
  </div>
</div>
