@let isDesktop = isDesktop$ | async;

<rp-table-wrapper [shouldShowSpinner]="isLoading()">
  <div class="filter" tableFilter>
    <ng-content select="[filters]" />
  </div>
  @if (isDesktop) {
    <rp-table
      #table
      tableBody
      class="table"
      [columns]="columns()"
      [itemsLength]="offersData().itemsCount"
      (sort)="onSort.emit($event)"
      (pagination)="onPagination.emit($event)"
      (action)="onAction.emit($event)"
    >
      @for (item of offersData().offers; track item.id) {
        <div rpRow [item]="item" class="exclusive">
          <rp-cell cellDef="logo">
            @if (item.photoUri) {
              <img class="logo__image" [rpLazyLoadImage]="item.photoUri" [alt]="'offers.info.descriptions.logo' | translate" />
            } @else {
              <rp-icon class="logo__image" [iconName]="icons.Avatar" />
            }
          </rp-cell>
          <rp-cell cellDef="id">{{ item.id }}</rp-cell>
          <rp-cell cellDef="name">
            <span class="highlighted">{{ item.name }}</span>
          </rp-cell>
          <rp-cell cellDef="geos">{{ item.geos.join() }}</rp-cell>
          <rp-cell cellDef="verticals">
            <span class="highlighted">{{ item.verticals | transformStringList }}</span>
          </rp-cell>
          <rp-cell cellDef="sources">
            <span class="highlighted">{{ item.trafficSources | transformStringList }}</span>
          </rp-cell>
          <rp-cell cellDef="model">{{ item.paymentModel }}</rp-cell>
          <rp-cell cellDef="rate$">{{ item.cpaRate | replaceEmpty }}</rp-cell>
          <rp-cell cellDef="rate%">{{ item.revsharePercent | replaceEmpty }}</rp-cell>
          <rp-cell cellDef="isExclusive">
            @if (item?.isHidden && item.isExclusive) {
              <div class="flex">
                <rp-icon [iconName]="icons.EyeOff" />
                <rp-icon [iconName]="icons.Exclusive" />
              </div>
            } @else if (item?.isHidden) {
              <rp-icon [iconName]="icons.EyeOff" />
            } @else if (item.isExclusive) {
              <rp-icon [iconName]="icons.Exclusive" />
            }
          </rp-cell>
          <rp-cell cellDef="actions">
            <rp-icon [iconName]="icons.Edit"></rp-icon>
          </rp-cell>
        </div>
      }
    </rp-table>
  } @else {
    <ng-container tableBody>
      <rp-offers-webmaster-mobile-table
        #table
        [data]="offersData()"
        (pagination)="onPagination.emit($event)"
        (select)="onSelect.emit($event)"
      />
    </ng-container>
  }
</rp-table-wrapper>
