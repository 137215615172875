import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NOTES_PROVIDER_TOKEN } from '../providers';
import { AddNoteParams, GetNotesRequest } from '../models';
import { NotesResponse } from '../models/notes-response.interface';

@Injectable()
export class NotesService {
  private readonly _notesProvider = inject(NOTES_PROVIDER_TOKEN);

  getNotes(request: GetNotesRequest): Observable<NotesResponse> {
    return this._notesProvider.getNotes(request);
  }

  addNote(params: AddNoteParams): Observable<void> {
    return this._notesProvider.addNote(params);
  }
}
