<mat-checkbox
  class="rp-checkbox"
  [ngClass]="{
    'rp-checkbox__no-label': !label(),
    'rp-checkbox__invalid': ngControl.control.touched && ngControl.control.invalid,
  }"
  [formControl]="ngControl.control"
  [required]="required()"
  [labelPosition]="labelPosition()"
  (change)="onChange()"
>
  {{ label() }}
</mat-checkbox>
